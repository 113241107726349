<template functional>
  <footer :class="classes" class="the-footer flex-wrap justify-between">
    <p>
      <!-- @formatter:off -->
      Copyright Rapid Education LLC. © 2020. All Rights Reserved.
      Powered by <a href="https://intellectualpoint.com/?utm_source=rapidportal&utm_medium=footer&utm_campaign=rapidportal-passive" target="_blank">Intellectual Point</a>.
      Created by <a href="https://osidex.io/?utm_source=rapidportal&utm_medium=footer&utm_campaign=rapidportal-passive" target="_blank">OSIDEX</a>.
      <!-- @formatter:on -->
    </p>
  </footer>
</template>

<script>
export default {
  name: 'the-footer',
  props: {
    classes: {
      type: String,
    },
  },
};
</script>
