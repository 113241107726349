<template>
  <div class="the-navbar__user-meta flex items-center" v-if="activeUserInfo.full_name">

    <div class="text-right leading-tight hidden sm:block">
      <p class="font-semibold">{{ activeUserInfo.full_name }}</p>
      <small>Available</small>
    </div>

    <vs-dropdown class="cursor-pointer" vs-custom-content vs-trigger-click>

      <div class="con-img ml-3">
        <img :src="activeUserInfo.photoURL" alt="user-img" class="rounded-lg shadow-md cursor-pointer block" height="40" key="onlineImg"
             v-if="activeUserInfo.photoURL" width="40"/>
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">

          <li
            @click="$router.push('/settings').catch(() => {})"
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
            <feather-icon icon="SettingsIcon" svgClasses="w-4 h-4"/>
            <span class="ml-2">Settings</span>
          </li>
          <li
            @click="$router.push('/discord').catch(() => {})"
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
            <feather-icon icon="MessageCircleIcon" svgClasses="w-4 h-4"/>
            <span class="ml-2">Discord</span>
          </li>

          <vs-divider class="m-1"/>

          <li
            @click="logout"
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4"/>
            <span class="ml-2">Logout</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>

export default {
  data () {
    return {}
  },
  computed: {
    activeUserInfo () {
      return this.$store.state.AppActiveUser
    }
  },
  methods: {
    logout () {
      localStorage.removeItem('userInfo')

      // If JWT login
      if (localStorage.getItem('accessToken')) {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        this.$router.push('/login').catch(() => {})
      }

      this.$router.push('/login').catch(() => {})
    }
  }
}
</script>
