/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default [
  {
    url: '/dashboard',
    name: 'Dashboard',
    tagColor: 'warning',
    icon: 'HomeIcon',
    i18n: 'Dashboard'
  },
  {
    header: 'Learn',
    items: [
      {
        url: '/seminars',
        name: 'Seminars',
        icon: 'VideoIcon',
        i18n: 'Seminars'
      },
    ]
  },
  {
    header: 'Marketplace',
    items: [
      {
        url: '/shop',
        name: 'Shop',
        icon: 'ShoppingBagIcon',
        i18n: 'Shop'
      },
      {
        url: '/subscriptions',
        name: 'Subscriptions',
        icon: 'LayersIcon',
        i18n: 'Subscriptions'
      }
    ]
  },
  /*{
    header: 'Support',
    i18n: 'Support',
    items: [
      {
        url: '/faq',
        name: 'FAQ',
        icon: 'HelpCircleIcon',
        i18n: 'FAQ'
      }
      // {
      //   url: '/knowledge-base',
      //   slug: 'page-knowledge-base',
      //   name: 'Knowledge Base',
      //   icon: 'InfoIcon',
      //   i18n: 'KnowledgeBase'
      // },
    ]
  }, */
  {
    header: 'Partner',
    icon: 'CommandIcon',
    i18n: 'Partner',
    rule: 'partner',
    items: [
      {
        url: '/partner',
        name: 'Partner Summary',
        icon: 'ActivityIcon',
        i18n: 'Partner Summary'
      },
    ]
  },
  {
    header: 'Management',
    i18n: 'Management',
    rule: 'employee',
    items: [
      {
        url: '/a/users',
        name: 'Users',
        icon: 'UsersIcon',
        i18n: 'Users'
      },
      {
        url: '/a/partners',
        name: 'Partners',
        icon: 'ActivityIcon',
        i18n: 'Partners'
      },
    ]
  },
  {
    header: 'Editor',
    i18n: 'Editor',
    rule: 'editor',
    items: [
      {
        url: '/a/blogs',
        name: 'Blogs',
        icon: 'Edit2Icon',
        i18n: 'Blogs'
      },
      /*{
        url: '/a/faq',
        name: 'FAQs',
        icon: 'HelpCircleIcon',
        i18n: 'FAQs'
      }*/
    ]
  },
  {
    header: 'Accounting',
    icon: 'Dollar2Icon',
    i18n: 'Accounting',
    rule: 'accountant',
    items: [
      {
        url: '/a/products',
        name: 'Products',
        icon: 'ShoppingBagIcon',
        i18n: 'Products'
      },
      {
        rule: 'accountant',
        url: '/a/invoices',
        name: 'Invoices',
        icon: 'FileTextIcon',
        i18n: 'Invoices'
      },
      {
        url: '/a/subscriptions',
        name: 'Subscriptions',
        icon: 'LayersIcon',
        i18n: 'Subscriptions'
      }
    ]
  },
  {
    header: 'DevOps',
    icon: 'WifiIcon',
    i18n: 'DevOps',
    rule: 'dev',
    items: [
      {
        url: '/a/services',
        name: 'Services',
        icon: 'CloudLightningIcon',
        i18n: 'Services'
      }
    ]
  },
  {
    header: 'User',
    icon: 'UserIcon',
    i18n: 'User',
    items: [
      {
        url: '/invoices',
        name: 'Invoices',
        icon: 'FileTextIcon',
        i18n: 'Invoices'
      },
      {
        url: '/settings',
        name: 'User Settings',
        icon: 'SettingsIcon',
        i18n: 'UserSettings',
      },
    ]
  }
]

